@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

body {
  font-family: "Poppins", Courier, monospace;
  background-color: #181f2e;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

/* removing arrows from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.button-click-animation {
  background: linear-gradient(
    to left,
    #284e57 0%,
    #284e57 20%,
    #284e57 50%,
    #2e3748 50%,
    #2e3748 20%,
    #2e3748 100%
  );
  background-size: 200% 100%;
  animation: colorTransition 1.5s linear;
}

@keyframes colorTransition {
  from {
    background-position: right;
  }
  to {
    background-position: left;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.text-loading {
  animation: pulse 1s infinite;
}
